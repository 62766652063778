import React from "react"
import {
  ActivityBox,
  DiscussBox,
  DefinitionBox,
  QuoteBox,
  ToSeeBox,
  CopyrightBox
} from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from "../worksheets.svg"
import { withPrefix } from 'gatsby'
import { YouTubeVideo } from '../../../components/video'
import { StaticImage } from 'gatsby-plugin-image'

const MedievalPilgrimage = () => (
  <Layout title="Medieval Pilgrimage">
    <SmallSectionHeader
      color={theme.colors.red.dark}
      image={headerImage}
      to="/pilgrimage"
    >
      Pilgrimage
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/pilgrimage', name: 'Pilgrimage' },
      { name: 'Medieval Pilgrimage' }
    ]} />
    <Page>
      <h1>Medieval Pilgrimage</h1>
      <p className="page-left">
        After Archbishop Thomas Becket argued with King Henry II over the division of power between the King and the Church he was killed by four of Henry’s knights whilst in the Cathedral. When miracles began to occur at the place Becket was killed, he was made a saint. The remains of martyrs like Saint Thomas were supposed to have special healing powers, and so thousands of pilgrims flocked to Canterbury. Holes and prayer niches were often made in the side of the saint’s tomb or shrine so pilgrims could get closer to their remains of the saint. After 50 years, a magnificent shrine was built to house his bones which were translated (moved) from his tomb in the Eastern Crypt.
      </p>
      <Figure
        caption="Pilgrims at St Thomas’ tomb as depicted in one of the Cathedral’s medieval stained glass windows. The picture shows the holes on the sides of the tomb."
        className="page-right"
      >
        <StaticImage
          src="./stained-glass.jpg"
          alt="Stained Glass depiction of pilgrims"
        />
      </Figure>
      <DiscussBox>What could a medieval pilgrim buy or bring home to show they had been on a pilgrimage? Is this different today?</DiscussBox>
      <h2>Offerings</h2>
      <p className="page-left">
        Another important part of the pilgrimage was the giving of an offering as thanks or in the hope of their prayers being answered. This offering would often be a silver coin, a candle or wax image symbolising the reason for the pilgrimage (for example they might offer a wax leg if they were hoping to have a sore, injured or diseased limb cured).
      </p>
      <Figure
        caption="The Trinity Chapel at Canterbury Cathedral, c. 1408 – Courtesy of University of York"
        className="page-right row-2"
      >
        <YouTubeVideo id="8eYsnZGcKQs" />
      </Figure>
      <p className="page-left">
        The great number of offerings at popular shrines meant that some pilgrimage sites (such as the shrine of St Thomas in Canterbury) became very wealthy and the shrines themselves became a wonderful sight covered in huge amounts of precious stones and metals. As such, many pilgrims came to see the shrine itself as a tourist attraction as well as the connection it had to the saint.
      </p>
      <DiscussBox>
        Were the motivations of medieval pilgrims different from the pilgrims who visit Canterbury Cathedral today?
      </DiscussBox>
      <Figure
        caption="An artist’s impression of what Cantebury may have looked like shortly after the time of Chaucer’s ‘Canterbury Tales”. – Courtesy of University of York."
      >
        <StaticImage
          src="./cathedral-precincts.jpg"
          alt="Computer generated depiction of the Cathedral Precincts as it would have been in medieval times"
        />
      </Figure>
      <p className="page-left">
        The red box below includes an original source which describes the experience of pilgrims arriving at the Cathedral on December 29th. This date was the Feast of the Martyrdom, the anniversary of the day that Saint Thomas Becket was killed.
      </p>
      <Figure
        caption="The Martyrdom Chapel during the Feast of the Martyrdom – Courtesy of University of York."
        className="page-right row-2"
      >
        <YouTubeVideo id="MdyRcitiyaE" />
      </Figure>
      <DefinitionBox title="Miracle" className="page-left">
        An extraordinary event taken as a sign of the power of God.
      </DefinitionBox>

      <QuoteBox cite="Customary of the Shrine of St Thomas, 1428">
        The shrine-keepers open the doors to the people who have kept a devoted watch throughout the night and let them enter. Many of them arrive with great devotion, eager to hear the life and works of the glorious martyr Thomas which are usually read in the mother tongue, and they store them up in their attentive minds and pious hearts. This having finished, the two clerks serve the people, who may be wearied by toil, drawing them to the heat of the fire that they have prepared, soothing them, and serving them sufficient bread, cheese, and ale… after Mass at the Martyrdom has finished and Mass at the Tomb of St Thomas is almost over, the shrine-keepers make it known to the people that a third Mass is celebrated in the shrine.
      </QuoteBox>

      <YouTubeVideo id="RsoJR0zWv_U" />

      <DiscussBox>Why did medieval people go on pilgrimage?</DiscussBox>


      <h2>What they wore</h2>
      <p className="page-left">
        From the twelfth century pilgrims were distinctively dressed in simple clothes.
      </p>
      <Figure
        caption="Artists impression of a typical medieval pilgrim."
        className="page-right row-2"
      >
        <StaticImage
          src="./outfit.png"
          alt="A depiction of a medieval outfit"
        />
      </Figure>
      <p className="page-left">
        Men wore a long tunic called a sclavein and carried a wooden staff and women wore clothes typical for a country dweller. Later some pilgrims wore a broad brimmed hat which could be decorated with badges that the pilgrims collected as souvenirs from the holy places they visited.
      </p>

      <Figure
        caption="A pilgrim badge depicting the head of St Thomas Becket."
        className="page-left row-2"
      >
        <StaticImage
          src="./badge.jpg"
          alt="Medieval Pilgrim's Badge"
        />
      </Figure>
      <h2 className="page-right">Badges</h2>
      <p className="page-right">
        Pilgrim badges were often sold at holy sites, they provided a source of income and stopped pilgrims breaking off and stealing bits of the shrine as souvenirs. They also helped to advertise the shrine of the saint and gave the pilgrim proof they had visited it. The pilgrim badge itself was also considered holy – it was a ‘secondary relic’ – and could give the protection of the saint associated with that holy place to the wearer.
      </p>
      <p className="page-right">
        As well as badges, pilgrims also acquired small flasks called ampullae which could be filled with ‘St Thomas’ water (initially said to be water mixed with an essence of Saint Thomas’ blood). These were sold to pilgrims visiting Canterbury Cathedral until 1538 when the shrine was destroyed.
      </p>
      <DefinitionBox title="Tertiary Relic">
        An object that has been in close contact with something associated with the Saint, such as the tomb or shrine.
      </DefinitionBox>

      <ToSeeBox
        items={[
          {
            location: "Martyrdom",
            items: [
              {
                title: "Altar of sword's point",
                content: "The place Becket was murdered."
              }
            ]
          },
          {
            location: "Crypt",
            items: [
              {
                title: "Eastern Crypt",
                content: "The original burial place of Becket from 1170 - 1220."
              }
            ]
          },
          {
            location: "Trinity Chapel",
            items: [
              {
                title: "Candle",
                content: "The site of the shrine built for Becket and completed in 1220. It was destroyed by Henry VIII as part of his reforms of the Church in England in 1538."
              },
              {
                title: "Miracle Windows",
                content: "Medieval stained glass windows that show the stories of the miracles associated with Saint Thomas, as written down by two monks from the Cathedral’s monastery."
              }
            ]
          },
        ]}
      />

      <h2>Activities</h2>
      <ActivityBox
        title="Pilgrimage activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/Pilgrimage.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘Pilgrimage’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "What is pilgrimage?", to: "/pilgrimage/what-is-pilgrimage" },
          {
            name: "Chaucer",
            to: "/pilgrimage/chaucer",
          },
          { name: "Pilgrimage today", to: "/pilgrimage/pilgrimage-today" },
        ]}
      />

      <hr />

      <CopyrightBox />

    </Page>
  </Layout>
)

export default MedievalPilgrimage;